<template>
	<van-pull-refresh v-model="isRefresh" @refresh="onRefresh">
		<div class="booking-order-list" style="min-height: 100vh;" ref="listView">
			<div :class="{'booking-order-item': true, ['level'+order.level]: true}" v-for="(order, index) in orderList" :key="index">
				<div class="booking-time">{{order.ScheduleDate}} {{order.StartTime.substring(0, 5)}}~{{order.EndTime.substring(0, 5)}} {{weeks[new Date(order.ScheduleDate).getDay()-1]}}</div>
				<div class="booking-doctor">
					<span :class="{'icon-doctor': true, 'man': order.Sex==1}"></span>
					<span class="doctor-name">{{order.DoctorName}}（{{order.ClinicName}}）</span>
				</div>
				<a :href="`tel:${order.ContactTel}`" class="tel-box">
					<img src="../../../../assets/imgs/mobile/user_center/img_iphone@2x.png" alt="" class="icon-tel">
					<span class="tel" v-text="order.ContactTel"></span>
				</a>
				<a class="address-box" :href="`https://www.google.com.hk/maps/search/?daddr=${order.ClinicName || ''}&center=${order.Lat},${order.Lng}&zoom=10`" target="_blank">
					<img src="../../../../assets/imgs/mobile/user_center/img_dib@2x.png" alt="" class="icon-address">
					<span class="address" v-text="order.Address"></span>
				</a>
				<div class="status" v-text="order.statusText"></div>
				<a href="javascript:;" :class="{'btn-cancel': true, 'hide': !order.enableCancel}" @click="cancelBooking(order)">取消預約</a>
			</div>

			<div class="load-more" v-if="isLoadMore">
				<div class="lds-default"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
				<span class="text">正在加载...</span>
			</div>
			<div class="load-finished" v-if="isLoadFinished">已全部加載</div>
		</div>
		<loading v-if="loading"/>
	</van-pull-refresh>
</template>

<script>
	import { Dialog } from 'vant';
	import Loading from '@/layouts/Loading.vue'
	import booking_order from '@/views/mixins/booking_order'
	export default {
		mixins: [
			booking_order
		],
		components: {
			Loading
		},
		data(){
			return {
				weeks: [
					'週一',
					'週二',
					'週三',
					'週四',
					'週五',
					'週六',
					'週日',
				],
				loading: true,
				isRefresh: false,
				isLoadMore: false,
				isLoadFinished: false,
				pageNo: 1,
				pageSize: 10,
				maxPage: 1,
				clientId: this.$store.getters.clientId,
				orderList: [],
				loadMore: false,
			}
		},
		mounted: function(){
			this.onRefresh()
		},
		methods: {
			onRefresh() {
				this.pageNo = 1
				this.loading = true
				this.isLoadMore = false
				this.isLoadFinished = false
				this.loadData(this.clientId, this.pageNo, this.pageSize, (data, error)=>{
					this.loading = false
					this.isRefresh = false
					this.orderList = (data && data.list) || []
					var total = (data && data.total) || 0
					if(total > 0) {
						this.maxPage = Math.floor((total + this.pageSize - 1)/this.pageSize)
						if(this.pageNo >= this.maxPage) {
							this.isLoadFinished = true
						}
					} else {
						if(!error) {
							this.isLoadFinished = true
						}
					}
				})
			},
			cancelBooking: function(order) {
                if(order && order.enableCancel) {
					Dialog.confirm({
						title: '確認取消預約？',
						confirmButtonText: '確定',
						cancelButtonText: '取消',
					})
					.then(() => {
						this.loading = true
						this.$store.dispatch('cancelBooking', {
							scheduleID: order.ScheduleID
						}).then(d=>{
							this._toast.success(this, '已經成功取消預約')
							this.pageNo = 1;
							var clientId = this.$store.getters.clientId
							this.loadData(clientId, this.pageNo, this.pageSize, (data, error)=>{
								this.orderList = (data && data.list) || []
								this.total = (data && data.total) || 0
								this.loading = false
								this.visible = this.total > 0
							})
						}).catch(error=>{
							this.loading = false
							this._toast.warning(this, error)
						})
					})
					.catch(() => {
					});
                }
			},
			handleScroll (event) {
				if((window.innerHeight + window.pageYOffset) > this.$refs.listView.clientHeight) {
					// 加载更多
					if(!this.loading && !this.isLoadMore && this.pageNo < this.maxPage) {
						this.isLoadMore = true
						this.loading = true
						this.loadData(this.clientId, this.pageNo+1, this.pageSize, (data, error)=>{
							this.loading = false
							this.isLoadMore = false
							this.orderList = this.orderList.concat((data && data.list) || [])
							if(!error) {
								this.pageNo = this.pageNo + 1
								var total = (data && data.total) || 0
								if(total > 0) {
									var maxPage = Math.floor((total + this.pageSize - 1)/this.pageSize)
									if(this.pageNo >= maxPage) {
										this.isLoadFinished = true
									}
								}
							}
						})
					}
				}
			}
		},
		created () {
			window.addEventListener('scroll', this.handleScroll);
		},
		destroyed () {
			window.removeEventListener('scroll', this.handleScroll);
		},
	}
</script>

<style lang="scss" scoped>
	.booking-order-list > .booking-order-item {
		display: flex;
		flex-direction: column;
		position: relative;
		margin: 23px 17px 0 17px;
		height: 288px;
		padding: 0 24px;
		overflow: hidden;
		background-image: url(../../../../assets/imgs/mobile/user_center/img_dibox@2x.png);
		background-size: 100% 100%;
		background-repeat: no-repeat;
	}

	.booking-order-list > .booking-order-item > .booking-time {
		color: #231F20;
		font-size: 34px;
		font-weight: bold;
		line-height: 32px;
		margin-top: 40px;
	}

	.booking-order-list > .booking-order-item.level2 > .booking-time {
		color: #969696;
	}

	.booking-order-list > .booking-order-item > .booking-doctor {
		display: flex;
		flex-direction: row;
		color: #231F20;
		font-size: 32px;
		font-weight: 400;
		line-height: 32px;
		margin-top: 29px;
	}

	.booking-order-list > .booking-order-item.level2 > .booking-doctor {
		color: #969696;
	}

	.booking-order-list > .booking-order-item > .booking-doctor > .icon-doctor {
		width: 30px;
		height: 31px;
		margin-right: 14px;
		background-image: url(../../../../assets/imgs/mobile/user_center/img_nvys@2x.png);
		background-size: 100% 100%;
		background-repeat: no-repeat;
	}

	.booking-order-list > .booking-order-item > .booking-doctor > .icon-doctor.man {
		background-image: url(../../../../assets/imgs/mobile/user_center/img_nanys@2x.png);
	}

	.booking-order-list > .booking-order-item > .address-box,
	.booking-order-list > .booking-order-item > .tel-box {
		display: flex;
		flex-direction: row;
		color: #969696;
		font-size: 24px;
		font-weight: 400;
		line-height: 20px;
	}

	.booking-order-list > .booking-order-item > .tel-box {
		margin-top: 64px;
	}

	.booking-order-list > .booking-order-item > .address-box {
		margin-top: 26px;
	}

	.booking-order-list > .booking-order-item > .tel-box > .icon-tel {
		display: inline-block;
		width: 20px;
		height: 20px;
		margin-right: 18px;
	}

	.booking-order-list > .booking-order-item > .address-box > .icon-address {
		display: inline-block;
		width: 16px;
		height: 20px;
		margin-right: 19px;
	}

	.booking-order-list > .booking-order-item > .status {
		position: absolute;
		right: 23px;
		top: 45px;
		padding: 0px 13px;
		height: 32px;
		font-weight: bold;
		color: #FF8000;
		font-size: 24px;
		line-height: 32px;
		background-color: #FFE3D5;
		border-radius: 16px;
	}

	.booking-order-list > .booking-order-item > .btn-cancel {
		position: absolute;
		right: 16px;
		top: 182px;
		padding: 0px 13px;
		color: #666;
		text-decoration: underline;
		font-size: 24px;
		line-height: 32px;
	}

	.booking-order-list > .booking-order-item > .btn-cancel.hide {
		display: none;
	}

	.booking-order-list > .booking-order-item.level1 > .status {
		background-color: #C5E6C7;
		color: #2A8737;
	}

	.booking-order-list > .booking-order-item.level2 > .status {
		background-color: #E8E8E8;
		color: #231F20;
	}

	// loadmore
	.load-more {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}

	.load-more > .text {
		margin-left: 24px;
	}
	.lds-default {
		display: inline-block;
		position: relative;
		width: 80px;
		height: 80px;
	}
	.lds-default div {
		position: absolute;
		width: 6px;
		height: 6px;
		background: #36C4D0;
		border-radius: 50%;
		animation: lds-default 1.2s linear infinite;
	}
	.lds-default div:nth-child(1) {
		animation-delay: 0s;
		top: 37px;
		left: 66px;
	}
	.lds-default div:nth-child(2) {
		animation-delay: -0.1s;
		top: 22px;
		left: 62px;
	}
	.lds-default div:nth-child(3) {
		animation-delay: -0.2s;
		top: 11px;
		left: 52px;
	}
	.lds-default div:nth-child(4) {
		animation-delay: -0.3s;
		top: 7px;
		left: 37px;
	}
	.lds-default div:nth-child(5) {
		animation-delay: -0.4s;
		top: 11px;
		left: 22px;
	}
	.lds-default div:nth-child(6) {
		animation-delay: -0.5s;
		top: 22px;
		left: 11px;
	}
	.lds-default div:nth-child(7) {
		animation-delay: -0.6s;
		top: 37px;
		left: 7px;
	}
	.lds-default div:nth-child(8) {
		animation-delay: -0.7s;
		top: 52px;
		left: 11px;
	}
	.lds-default div:nth-child(9) {
		animation-delay: -0.8s;
		top: 62px;
		left: 22px;
	}
	.lds-default div:nth-child(10) {
		animation-delay: -0.9s;
		top: 66px;
		left: 37px;
	}
	.lds-default div:nth-child(11) {
		animation-delay: -1s;
		top: 62px;
		left: 52px;
	}
	.lds-default div:nth-child(12) {
		animation-delay: -1.1s;
		top: 52px;
		left: 62px;
	}
	@keyframes lds-default {
		0%, 20%, 80%, 100% {
			transform: scale(1);
		}
		50% {
			transform: scale(1.5);
		}
	}
	//loadmore end

	.load-finished {
		height: 44px;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		position: relative;
		font-size: 24px;
		font-weight: 400;
		color: #99999991;
	}

	.load-finished::before {
		content: '';
		width: 120px;
		height: 1px;
		background-color: #99999991;
		right: -130px;
		margin-right: 20px;
		top: 50%;
		transform: translateY(-50%);
	}

	.load-finished::after {
		content: '';
		width: 120px;
		height: 1px;
		background-color: #99999991;
		left: -130px;
		top: 50%;
		margin-left: 20px;
		transform: translateY(-50%);
	}

</style>
